import {
	Box,
	Collapse,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	tableCellClasses,
} from '@mui/material'
import { ReactComponent as ArrowCloseDone } from 'assets/arrow_close_logo.svg'
import moment from 'moment'
import { useEffect, useState } from 'react'
import CattlesApiService from 'services/cattlesApiServices'
import { useAppSelector } from 'store/hooks'
import { COLORS } from 'styles/colors'
import { ChipItem } from 'ui/ChipItem'
import CustomTableRow from 'ui/table/CustomTableRow'
import { TypographyHeaderSecondary } from 'ui/typography/TypographyHeaderSecondary'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'
import { ICheckupList, PositionHoof } from '../models'
import { getTranslationStatus } from '../processes'

type ViewHistoryBlockType = {
	cowId: number
}
const initHoodData = [
	{ title: 'Все', key: 'all', count: 0 },
	{ title: 'Переднее левое', key: PositionHoof.Front_Left, count: 0 },
	{ title: 'Переднее правое', key: PositionHoof.Front_Right, count: 0 },
	{ title: 'Заднее левое', key: PositionHoof.Rear_Left, count: 0 },
	{ title: 'Заднее правое', key: PositionHoof.Rear_Right, count: 0 },
]
const ViewHistoryBlock = (props: ViewHistoryBlockType) => {
	const { cowId } = props
	const [open, setOpen] = useState<boolean>(false)
	const [examinatorsInfo, setExaminatorsInfo] = useState<ICheckupList[]>([])
	const [activeFilter, setActiveFilter] = useState('all')
	const [hoofData, setHoofData] = useState(initHoodData)
	const { diagnosesList, diagnosesListPending } = useAppSelector(
		state => state.cattlesSlice
	)
	const handleOpenTable = () => {
		setOpen(prev => !prev)
	}
	const transformExaminationData = (data, diagnosesList) => {
		return data.map((el, index) => ({
			rowKey: `examinatorRowId${el.id}`,
			height: '100%',
			cellItems: [
				{
					padding: '16px 8px 0px 0px',
					children: (
						<TypographyPrimary>
							{moment(el.created_dt).format('DD.MM.YY')}
						</TypographyPrimary>
					),
				},
				{
					padding: '16px 8px 0px 0px',
					children: (
						<TypographyPrimary key={`limpness-${index}`}>
							{el.limpness === 1 ? 'Здоровая' : 'Хромая'}{' '}
							{/* <span style={{ color: COLORS.secondaryFont }}>
								{morphForWords(el.limpness, ['балл', 'балла', 'баллов'])}
							</span> */}
						</TypographyPrimary>
					),
				},
				{
					padding: '16px 8px 0px 0px',
					children: `${el.examiner.lastname}. ${el.examiner.firstname[0]}.`,
				},
				{
					padding: '16px 8px 0px 0px',
					children: (
						<Box
							display={'flex'}
							flexDirection={'column'}
							key={`diagnoses-${index}`}
							gap={'12px'}
						>
							{el.diagnosis.map((diagnose, diagIndex) => (
								<TypographyPrimary key={`diagnose-${index}-${diagIndex}`}>
									{diagnose.sickness
										.map(sickness => {
											const found = diagnosesList.find(el => el.id === sickness)
											return found ? found.title : 'Неизвестный диагноз'
										})
										.join(', ')}
								</TypographyPrimary>
							))}
							{el.dispose_recommended && (
								<span
									style={{
										width: '74px',
										padding: '4px 8px',
										borderRadius: '12px',
										fontSize: '12px',
										color: COLORS.error,
										backgroundColor: COLORS.deviationOpacity,
									}}
								>
									Выбытие
								</span>
							)}
						</Box>
					),
				},
				{
					padding: '16px 8px 0px 0px',
					children: (
						<Box
							display={'flex'}
							flexDirection={'column'}
							key={`diagnoses-${index}`}
							gap={'14px'}
						>
							{el.diagnosis.map((diagnose, posIndex) => (
								<TypographyPrimary key={`position-${index}-${posIndex}`}>
									{getTranslationStatus(diagnose.position)}
								</TypographyPrimary>
							))}
						</Box>
					),
				},
				{
					padding: '16px 8px 0px 0px',
					children: (
						<Box
							display={'flex'}
							flexDirection={'column'}
							key={`diagnoses-${index}`}
							gap={'14px'}
						>
							{el.diagnosis.map((diagnose, commentIndex) => (
								<TypographySecondary14 key={`comment-${index}-${commentIndex}`}>
									{diagnose.comment}
								</TypographySecondary14>
							))}
						</Box>
					),
				},
			],
		}))
	}
	const updateHoofDataCounts = (data: ICheckupList[]) => {
		const updatedHoofData = hoofData.map(item => ({
			...item,
			count: 0,
		}))

		data.forEach(exam => {
			exam.diagnosis.forEach(diagnose => {
				const hoof = diagnose.position
				const hoofItem = updatedHoofData.find(item => item.key === hoof)
				if (hoofItem) {
					hoofItem.count += 1
				}
				const allItem = updatedHoofData.find(item => item.key === 'all')
				if (allItem) {
					allItem.count += 1
				}
			})
		})
		setHoofData(updatedHoofData)
	}
	useEffect(() => {
		CattlesApiService.getExaminationCowInfoByCowId(cowId).then(data => {
			setExaminatorsInfo(data)
			updateHoofDataCounts(data)
		})
	}, [cowId, diagnosesListPending])

	const filteredData =
		activeFilter === 'all'
			? examinatorsInfo
			: examinatorsInfo.filter(el =>
					el.diagnosis.some(d => d.position.includes(activeFilter))
			  )
	const transformedData = transformExaminationData(filteredData, diagnosesList)

	return (
		<>
			<Box
				display={'flex'}
				flexDirection={'row'}
				justifyContent={'space-between'}
				alignItems={'center'}
			>
				<TypographyHeaderSecondary
					onClick={handleOpenTable}
					sx={{ cursor: 'pointer' }}
				>
					История осмотров
				</TypographyHeaderSecondary>
				<IconButton
					onClick={handleOpenTable}
					disableRipple
					sx={{
						p: 0,
						transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
						transition: '.3s ease-in-out',
					}}
				>
					<ArrowCloseDone stroke={COLORS.icon} />
				</IconButton>
			</Box>
			<Collapse in={open} unmountOnExit timeout='auto'>
				{hoofData?.map(el => (
					<ChipItem
						onClick={() => setActiveFilter(el.key)}
						key={el.key}
						label={el.title + ' ' + el.count}
						sx={{
							backgroundColor: activeFilter === el.key && COLORS.main,
							color: activeFilter === el.key && '#fff',
							margin: '0px 8px 16px 0px',
						}}
					/>
				))}
				<TableContainer
					sx={{
						overflowY: 'auto',
						overflowX: 'hidden',
						height: '267px',
						'&::-webkit-scrollbar': {
							width: 4,
						},
						'&::-webkit-scrollbar-track': {
							backgroundColor: '#DBDBDB',
							borderRadius: 12,
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: '#5222D0',
							borderRadius: 12,
						},
					}}
				>
					<Table>
						<TableHead
							sx={{
								[`& .${tableCellClasses.root}`]: {
									backgroundColor: '#fff',
								},
							}}
						>
							<CustomTableRow
								rowKey={tableHead.rowKey}
								cellItems={tableHead.cellItems}
								rowBorderBottom={tableHead.rowBorderBottom}
								rowHeight={tableHead.height}
							/>
						</TableHead>
						<TableBody>
							{transformedData?.length === 0 ? (
								<TableRow
									sx={{
										width: '100%',
										[`& .${tableCellClasses.root}`]: {
											borderBottom: 'none',
											height: '200px',
										},
									}}
								>
									<TableCell colSpan={12} align={'center'} padding={'none'}>
										<TypographySecondary14>
											Признаков хромоты ранее не было обнаружено
										</TypographySecondary14>
									</TableCell>
								</TableRow>
							) : (
								transformedData.map(el => (
									<CustomTableRow
										key={el.rowKey}
										rowKey={el.rowKey}
										cellItems={el.cellItems}
										rowHeight={el.height}
									/>
								))
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TypographySecondary>
					Всего: {examinatorsInfo?.length}
				</TypographySecondary>
			</Collapse>
		</>
	)
}

export default ViewHistoryBlock

const tableHead = {
	rowKey: 'tableHistoryViewHead',
	rowBorderBottom: COLORS.secondaryFontOpacity,
	height: '40px',
	cellItems: [
		{
			width: '10%',
			children: (
				<TypographySecondary>
					Дата <br /> осмотра
				</TypographySecondary>
			),
		},
		{
			width: '10%',
			children: <TypographySecondary>Хромота</TypographySecondary>,
		},
		{
			width: '10%',
			children: <TypographySecondary>Внес изменения</TypographySecondary>,
		},
		{
			width: '20%',
			children: <TypographySecondary>Диагноз</TypographySecondary>,
		},
		{
			width: '10%',
			children: (
				<TypographySecondary>
					Больное
					<br />
					копыто
				</TypographySecondary>
			),
		},
		{
			width: '20%',
			children: <TypographySecondary>Комментарий</TypographySecondary>,
		},
	],
}
