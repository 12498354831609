import { Box } from '@mui/material'
import { ReactComponent as HealthLogo } from 'assets/icons/health-logo.svg'
import { TreatmentStatus } from 'components/cattles/lamenessPage/shared/models'
import { getTranslationStatus } from 'components/cattles/lamenessPage/shared/processes'
import moment from 'moment'
import { COLORS } from 'styles/colors'
import { CardStandart } from 'ui/CardStandart'
import InfoChipItem from 'ui/InfoChipItem'
import { TypographyPrimary16Bold } from 'ui/typography/TypographyPrimary16Bold'
import { TypographySecondary } from 'ui/typography/TypographySecondary'

const LamenessWidjet = props => {
	const { lameness, lastMilking, treatmentStatus } = props

	const isError =
		treatmentStatus !== TreatmentStatus.Completed &&
		treatmentStatus !== TreatmentStatus.NotRequired
	const isTreatment = treatmentStatus === TreatmentStatus.Treated
	const translateStatus = getTranslationStatus(treatmentStatus)
	return (
		<CardStandart
			sx={{
				width: '100%',
				padding: '24px',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
			}}
		>
			<Box display={'flex'} flexDirection={'row'} gap={'8px'} mr={'4px'}>
				<span>
					<HealthLogo
						fill={
							isTreatment
								? COLORS.health
								: isError
								? COLORS.error
								: COLORS.secondary
						}
						stroke={
							isTreatment
								? COLORS.health
								: isError
								? COLORS.error
								: COLORS.secondary
						}
					/>
				</span>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						gap: '8px',
					}}
				>
					<TypographyPrimary16Bold
						sx={{
							color: isTreatment
								? COLORS.health
								: isError
								? COLORS.error
								: COLORS.black,
						}}
					>
						{lameness ? lameness : '-'}{' '}
						<span
							style={{
								color: COLORS.secondary,
								fontWeight: 400,
								fontSize: '12px',
							}}
						>
							балла
						</span>
					</TypographyPrimary16Bold>
					<TypographySecondary>Хромота</TypographySecondary>
				</Box>
			</Box>
			<Box
				display={'flex'}
				flexDirection={'column'}
				justifyContent={isError || isTreatment ? 'space-between' : 'flex-end'}
				gap={'8px'}
			>
				{isError && (
					<InfoChipItem
						bgColor={isTreatment ? COLORS.healthOpacity10 : COLORS.error10}
						label={translateStatus}
						labelColor={isTreatment ? COLORS.health : COLORS.error}
					/>
				)}

				<TypographySecondary alignSelf={'flex-end'}>
					{lastMilking ? `замер ${moment(lastMilking).format('D.MM.YY')}` : '-'}
				</TypographySecondary>
			</Box>
		</CardStandart>
	)
}

export default LamenessWidjet
