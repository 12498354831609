import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axAPI from '../http'
import {
	IAuthPermissions,
	IAuthResponse,
	IAuthState,
} from '../models/IUserData'

export const authLogin = createAsyncThunk(
	'auth/login',
	async (
		authData: { username: string; password: string },
		{ dispatch, rejectWithValue }
	) => {
		try {
			const response = await axAPI({
				method: 'POST',
				url: '/auth/jwt/create/',
				data: { ...authData, username: authData.username.toLowerCase() },
			})

			if (response.status !== 200) {
				throw new Error('HTTP request error!')
			}

			await dispatch(fetchPermissions())
			const responseData = response.data
			const apiToken = responseData

			return apiToken
		} catch (error) {
			let errorMessage = 'Authentication failed!'
			if (error instanceof Error) {
				errorMessage = error.message
			}
			return rejectWithValue(errorMessage)
		}
	}
)

export const authSetPassword = createAsyncThunk(
	'auth/set_password',
	async (
		setData: { current_password: string; new_password: string },
		{ rejectWithValue }
	) => {
		try {
			const response = await axAPI({
				method: 'POST',
				url: '/auth/users/set_password/',
				data: setData,
				validateStatus: status =>
					status === 400 || status === 201 || status === 204,
			})

			if (response.status === 400) {
				if (response.data.current_password !== undefined) {
					throw new Error(response.data.current_password[0])
				} else if (response.data.new_password !== undefined) {
					throw new Error(response.data.new_password[0])
				} else {
					throw new Error('Ошибка при изменении пароля!')
				}
			}

			return response.data
		} catch (error) {
			let errorMessage = 'Authentification failed!'
			if (error instanceof Error) {
				errorMessage = error.message
			}
			return rejectWithValue(errorMessage)
		}
	}
)
export const authLogout = createAsyncThunk(
	'auth/logout',
	async (_, { rejectWithValue }) => {
		try {
			const response = await axAPI({
				method: 'POST',
				url: 'auth/token/logout/',
				data: initialState.apiToken,
			})

			if (response.status !== 204) {
				throw new Error('HTTP request error!')
			}
			sessionStorage.removeItem('permissions')
			return response.data
		} catch (error) {
			let errorMessage = 'Failed to logout current user!'
			if (error instanceof Error) {
				errorMessage = error.message
			}
			return rejectWithValue(errorMessage)
		}
	}
)

export const fetchPermissions = createAsyncThunk(
	'auth/fetchPermissions',
	async (_, { rejectWithValue }) => {
		try {
			const response = await axAPI.get('/auth/permissions')

			if (response.status !== 200) {
				throw new Error('HTTP request error!')
			}
			return response.data
		} catch (error) {
			let errorMessage = 'Failed to fetch permissions!'
			if (error instanceof Error) {
				errorMessage = error.message
			}
			return rejectWithValue(errorMessage)
		}
	}
)
const initialState: IAuthState = {
	apiToken: localStorage.getItem('apiToken'),
	refreshToken: localStorage.getItem('refreshToken'),
	authRequest: false,
	authError: '',
	passwordUpdated: false,
	permissions: JSON.parse(sessionStorage.getItem('permissions')) || null,
	authRejected: false,
	loading: false,
}

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		clearPermissions: state => {
			state.permissions = null
			sessionStorage.removeItem('permissions')
		},
		resetInputError: state => {
			state.passwordError = ''
		},
	},
	extraReducers: {
		[authLogin.fulfilled.type]: (
			state,
			action: PayloadAction<IAuthResponse>
		) => {
			state.authRequest = false
			state.authError = action.payload.detail
			state.authRejected = false
			state.apiToken = action.payload.access
			state.loading = false
			state.refreshToken = action.payload.refresh
			localStorage.setItem('apiToken', action.payload.access)
			localStorage.setItem('refreshToken', action.payload.refresh)
		},
		[authLogin.pending.type]: state => {
			state.authRequest = true
			state.authError = ''
			state.authRejected = false
			state.loading = true
			state.apiToken = ''
			state.refreshToken = ''
			localStorage.setItem('apiToken', '')
			localStorage.setItem('refreshToken', '')
		},
		[authLogin.rejected.type]: (
			state,
			action: PayloadAction<IAuthResponse>
		) => {
			state.authRequest = false
			state.authError = action.payload.detail
			state.authRejected = true
			state.loading = false
			state.apiToken = action.payload.access
			state.refreshToken = action.payload.refresh
			localStorage.setItem('apiToken', '')
			localStorage.setItem('refreshToken', '')
		},
		[fetchPermissions.fulfilled.type]: (
			state,
			action: PayloadAction<IAuthPermissions>
		) => {
			state.permissions = action.payload
			sessionStorage.setItem('permissions', JSON.stringify(action.payload))
		},

		[authLogout.fulfilled.type]: (
			state,
			action: PayloadAction<IAuthResponse>
		) => {
			state.authRequest = false
			state.authError = action.payload.detail
			state.apiToken = ''
			localStorage.removeItem('apiToken')
			localStorage.removeItem('refreshToken')
			state.passwordUpdated = false
			state.passwordError = ''
			state.permissions = null
			sessionStorage.removeItem('permissions')
		},
		[authLogout.pending.type]: state => {
			state.authRequest = true
			state.authError = ''
		},
		[authLogout.rejected.type]: (
			state,
			action: PayloadAction<IAuthResponse>
		) => {
			state.authRequest = false
			state.authError = action.payload.detail
			localStorage.removeItem('apiToken')
			localStorage.removeItem('refreshToken')
		},
		[authSetPassword.fulfilled.type]: (
			state,
			action: PayloadAction<IAuthResponse>
		) => {
			state.authRequest = false
			state.authError = action.payload.detail
			state.passwordError = ''
			state.passwordUpdated = true
		},
		[authSetPassword.pending.type]: state => {
			state.authRequest = true
			state.authError = ''
			state.passwordError = ''
			state.passwordUpdated = false
		},
		[authSetPassword.rejected.type]: (state, action: PayloadAction<string>) => {
			state.authRequest = false
			state.passwordUpdated = false
			state.passwordError = action.payload
		},
	},
})

export const selectPermissions = state => state.authReducer.permissions
export const { clearPermissions, resetInputError } = authSlice.actions

export default authSlice.reducer
