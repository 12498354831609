import {
	Box,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	tableCellClasses,
} from '@mui/material'
import { roundingNumber } from 'components/shared/processes'
import { ICowsList } from 'models/ICattlesData'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { COLORS } from 'styles/colors'
import TableSkeleton from 'ui/skeleton/TableSkeleton'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'
import { TypographySecondaryBold16 } from 'ui/typography/TypographySecondaryBold16'
import { ReactComponent as BarchartInfoLogo } from '../../../../assets/barchart_info_logo.svg'
import { ReactComponent as ArrowNext } from '../../../../assets/fill_back_arrow.svg'
import { ReactComponent as TagNumberIcon } from '../../../../assets/icons/tag-icon.svg'

interface ICattlesTable {
	cattlesInfo: ICowsList[]
	fetchingCowsList: boolean
}

const CattlesTable = (props: ICattlesTable) => {
	const { cattlesInfo, fetchingCowsList } = props

	const navigate = useNavigate()
	const navigateCowPasportPage = (tagId, id) => {
		navigate(`/cattles/cow/${tagId}`, { state: { cowId: id } })
		window.scrollTo(0, 0)
	}

	if (fetchingCowsList) {
		return (
			<TableSkeleton
				widthForHeader={'100%'}
				heightForHeader={'34px'}
				widthForBody={'100%'}
				heightForBody={'34px'}
				rowCount={36}
				totalHeight={'calc(100vh - 350px)'}
			/>
		)
	}

	return (
		<TableContainer
			sx={{
				overflowY: 'auto',
				overflowX: 'hidden',
				height: 'calc(100vh - 350px)',
				'&::-webkit-scrollbar': {
					width: 4,
				},
				'&::-webkit-scrollbar-track': {
					backgroundColor: '#DBDBDB',
					borderRadius: 12,
				},
				'&::-webkit-scrollbar-thumb': {
					backgroundColor: '#5222D0',
					borderRadius: 12,
				},
			}}
		>
			<Table stickyHeader>
				<TableHead
					sx={{
						[`& .${tableCellClasses.root}`]: {
							backgroundColor: '#fff',
						},
					}}
				>
					<TableRow sx={{ width: '100%', height: '50px' }}>
						<TableCell
							align={'left'}
							padding={'none'}
							width={'7%'}
							sx={{ paddingRight: '5px' }}
						>
							<Box
								display={'flex'}
								flexDirection={'row'}
								alignItems={'center'}
								gap={'4px'}
							>
								<TagNumberIcon />
								<TypographySecondary>
									Номер
									<br />
									бирки
								</TypographySecondary>
							</Box>
						</TableCell>
						<TableCell align={'left'} padding={'none'} width={'20%'}>
							<TypographySecondary>Группа</TypographySecondary>
						</TableCell>
						<TableCell
							align={'left'}
							padding={'none'}
							width={'95px'}
							sx={{ paddingRight: '5px' }}
						>
							<TypographySecondary>
								Суточный
								<br />
								надой
							</TypographySecondary>
						</TableCell>
						<TableCell
							align={'left'}
							padding={'none'}
							width={'95px'}
							sx={{ paddingRight: '5px' }}
						>
							<TypographySecondary>
								Последнее
								<br />
								доение
							</TypographySecondary>
						</TableCell>
						{/* <TableCell
							align={'left'}
							padding={'none'}
							width={'95px'}
							sx={{ paddingRight: '5px' }}
						>
							<TypographySecondary>Упитанность</TypographySecondary>
						</TableCell> */}
						<TableCell align={'left'} padding={'none'} width={'42%'}>
							<TypographySecondary>Хромота</TypographySecondary>
						</TableCell>
						<TableCell align={'left'} padding={'none'} width={'7%'}>
							<TypographySecondary>Действия</TypographySecondary>
						</TableCell>
					</TableRow>
				</TableHead>
				{cattlesInfo.length === 0 ? (
					<TableBody>
						<TableRow
							sx={{
								width: '100%',
								[`& .${tableCellClasses.root}`]: {
									borderBottom: 'none',
									height: '300px',
								},
							}}
						>
							<TableCell colSpan={6} align={'center'} padding={'none'}>
								<TypographySecondaryBold16>
									Нет данных
								</TypographySecondaryBold16>
							</TableCell>
						</TableRow>
					</TableBody>
				) : (
					<TableBody>
						{cattlesInfo.map((el, index) => {
							if (el.farm_cow_number) {
								return (
									<TableRow
										key={index}
										sx={{
											height: '34px',
											'&:hover': {
												background: COLORS.mainSecondaryOpacity,
											},
											[`& .${tableCellClasses.root}`]: {
												borderBottom: 'none',
											},
										}}
									>
										<TableCell align={'left'} padding={'none'}>
											<TypographyPrimary>{el.tag_id}</TypographyPrimary>
										</TableCell>
										<TableCell align={'left'} padding={'none'}>
											<TypographyPrimary>{el.group.title}</TypographyPrimary>
										</TableCell>
										<TableCell align={'left'} padding={'none'}>
											<TypographyPrimary>
												{roundingNumber(el.today_milking_amount, 1)}{' '}
												<span
													style={{ fontSize: '12px', color: COLORS.secondary }}
												>
													л
												</span>
											</TypographyPrimary>
										</TableCell>
										<TableCell align={'left'} padding={'none'}>
											{el.last_milking_dt ? (
												<TypographySecondary14>
													{moment(el.last_milking_dt).format('DD.MM.YY')} <br />{' '}
													{moment(el.last_milking_dt).format('HH:mm:ss')}
												</TypographySecondary14>
											) : (
												<TypographySecondary14>-</TypographySecondary14>
											)}
										</TableCell>
										{/* <TableCell align={'left'} padding={'none'}>
											{!el.fatness ? (
												<BarchartInfoLogo
													width={'16px'}
													height={'16px'}
													style={{ color: COLORS.health }}
												/>
											) : (
												<TypographyPrimary>
													{el.fatness}{' '}
													<span
														style={{
															fontSize: '12px',
															color: COLORS.secondary,
														}}
													>
														б
													</span>
												</TypographyPrimary>
											)}
										</TableCell> */}
										<TableCell align={'left'} padding={'none'}>
											<TypographyPrimary>
												{/* {el.limpness}{' '}
												<span
													style={{ fontSize: '12px', color: COLORS.secondary }}
												>
													б
												</span> */}
												{el.limpness === 1 ? 'Здоровая' : 'Хромая'}
											</TypographyPrimary>
										</TableCell>
										<TableCell align={'center'}>
											<Button
												disableRipple={true}
												sx={{
													'&:hover': {
														backgroundColor: 'transparent',
													},
												}}
												onClick={() => navigateCowPasportPage(el.tag_id, el.id)}
											>
												<ArrowNext
													style={{
														cursor: 'pointer',
														transform: 'rotate(180deg)',
													}}
													stroke={COLORS.secondary}
												/>
											</Button>
										</TableCell>
									</TableRow>
								)
							} else {
								return (
									<TableRow
										key={index}
										sx={{
											[`& .${tableCellClasses.root}`]: {
												borderBottom: 'none',
											},
											height: '34px',
											backgroundColor: COLORS.healthOpacity10,
										}}
									>
										<TableCell align={'left'} padding={'none'}>
											<Box
												display={'flex'}
												flexDirection={'row'}
												alignItems={'center'}
												gap={'4px'}
											>
												<BarchartInfoLogo
													width={'16px'}
													height={'16px'}
													style={{ color: COLORS.health }}
												/>
												<TypographyPrimary>{el.tag_id}</TypographyPrimary>
											</Box>
										</TableCell>
										<TableCell align={'left'} padding={'none'} colSpan={6}>
											<TypographySecondary14>
												Корова не найдена в программе по надоям. Актуализируйте
												систему.
											</TypographySecondary14>
										</TableCell>
									</TableRow>
								)
							}
						})}
					</TableBody>
				)}
			</Table>
		</TableContainer>
	)
}

export default CattlesTable
