import { Box } from '@mui/material'
import { ICowLightInfo } from 'models/ICattlesData'
import React from 'react'
import SkeletonEmptyCard from 'ui/skeleton/SkeletonEmptyCard'
import LactationWidjet from '../shared/LactationWidjet'
import LamenessWidjet from '../shared/LamenessWidjet'

interface WidjetProps {
	cattleInfo: ICowLightInfo
}

const WidjetsInPasportPage = (props: WidjetProps) => {
	const { cattleInfo } = props

	return (
		<Box
			sx={{
				display: ' flex',
				flexDirection: 'row',
				gap: '24px',
				width: '100%',
				'@media(max-width: 1200px)': {
					flexDirection: 'column',
				},
			}}
		>
			{cattleInfo ? (
				<>
					<LactationWidjet
						dailyYield={cattleInfo?.today_milking_amount}
						lactationDay={cattleInfo?.lactation_length}
						lactationNumber={cattleInfo?.lactation_count}
						yieldStatus={cattleInfo?.yield_status}
					/>

					{/* <FatnessWidjet
						fatness={cattleInfo?.fatness}
						lastMilking={cattleInfo?.fatness_change_dt}
						lactationDay={cattleInfo?.lactation_length}
						isError={cattleInfo?.fatness_status === 'attention'}
						isWarning={cattleInfo?.fatness_status === 'expired'}
					/> */}

					<LamenessWidjet
						lameness={cattleInfo?.limpness}
						lastMilking={cattleInfo?.limpness_change_dt}
						treatmentStatus={cattleInfo?.treatment_status}
					/>
				</>
			) : (
				<>
					{Array.from({ length: 3 }, (_, index) => (
						<React.Fragment key={index}>
							<SkeletonEmptyCard height={'36px'} />
						</React.Fragment>
					))}
				</>
			)}
		</Box>
	)
}

export default WidjetsInPasportPage
