import { Box } from '@mui/material'
import Skeleton from 'react-loading-skeleton'
import CardWidjet from 'ui/widjets/CardWidjet'
import { textForHeaderWidjets } from '../../utils'
import DailyYieldCard from '../shared/DailyYieldCard'
import DonutChart from '../shared/DonutChart'
import DonutDescription from '../shared/DonutDescription'
import NotAvailableModule from '../shared/NotAvailableModule'
import { generateColorsAndValuesArray } from '../utils'

const WidjetsHeader = props => {
	const { dailyStatistics, loadingStatistics } = props
	const lamenessGroups = generateColorsAndValuesArray(
		dailyStatistics?.limpness_data
	)
	const fatnessGroups = generateColorsAndValuesArray(
		dailyStatistics?.fatness_data
	)
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				gap: '24px',
				'@media (max-width: 1200px)': {
					flexDirection: 'column',
				},
			}}
		>
			{textForHeaderWidjets.map((el, index) => (
				<Box width={'100%'} key={index}>
					<CardWidjet
						title={loadingStatistics ? <Skeleton width={150} /> : el.text}
						tooltipContent={null}
						pathNavigate={index === 1 && '/cattles/lameness'}
						content={
							loadingStatistics ? (
								<Skeleton height={125} />
							) : (
								(index === 0 && (
									<DailyYieldCard
										milking_data={dailyStatistics?.milking_data}
									/>
								)) ||
								(index === 2 && (
									// <Box
									// 	sx={{
									// 		display: 'flex',
									// 		height: '100%',
									// 		flexDirection: 'row',
									// 		alignItems: 'center',
									// 		justifyContent: 'space-between',
									// 		width: '100%',
									// 		gap: '16px',
									// 	}}
									// >
									// 	<DonutDescription
									// 		description={dailyStatistics?.fatness_data}
									// 	/>
									// 	<DonutChart
									// 		categories={fatnessGroups.categories}
									// 		colors={fatnessGroups.colors}
									// 	/>
									// </Box>
									<NotAvailableModule />
								)) ||
								(index === 1 && (
									<Box
										sx={{
											display: 'flex',
											height: '100%',
											flexDirection: 'row',
											alignItems: 'center',
											justifyContent: 'space-between',
											width: '100%',
											gap: '16px',
										}}
									>
										<DonutDescription
											description={dailyStatistics?.limpness_data}
										/>
										<DonutChart
											categories={lamenessGroups.categories}
											colors={lamenessGroups.colors}
										/>
									</Box>
								))
							)
						}
					/>
				</Box>
			))}
		</Box>
	)
}

export default WidjetsHeader
