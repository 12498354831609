import { Box, Collapse } from '@mui/material'
import { ReactComponent as ArrowSvg } from 'assets/arrow_open_logo.svg'
import { useState } from 'react'
import { CardStandart } from 'ui/CardStandart'
import { TypographyHeader } from 'ui/typography/TypographyHeader'
import { EnumForHealthTabs } from '../model'
import HoofHealthTable from '../shared/HoofHealthTable'

interface HealthCardProps {
	cowId: number
}

const HealthCard = (props: HealthCardProps) => {
	const { cowId } = props
	const [isOpenCard, setIsOpenCard] = useState<boolean>(true)
	const [showedHealthTable, setShowedHealthTable] = useState<EnumForHealthTabs>(
		EnumForHealthTabs.RoofHealth
	)
	return (
		<CardStandart sx={{ p: '24px' }}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					cursor: 'pointer',
				}}
				onClick={() => setIsOpenCard(prev => !prev)}
			>
				<TypographyHeader>Здоровье конечностей</TypographyHeader>
				<ArrowSvg
					style={{
						transform: isOpenCard ? 'rotate(0deg)' : 'rotate(180deg)',
						transition: 'all .1s ease-in-out',
					}}
				/>
			</Box>
			<Collapse in={isOpenCard} unmountOnExit sx={{ mt: '16px' }}>
				{/* <TabsFilter
					availableTabs={availableTabs}
					onClick={key => setShowedHealthTable(key)}
				/> */}
				{showedHealthTable === EnumForHealthTabs.RoofHealth ? (
					<HoofHealthTable cowId={cowId} />
				) : (
					// <FatnessHealthTable cowId={cowId} />
					<></>
				)}
			</Collapse>
		</CardStandart>
	)
}

export default HealthCard

const availableTabs = [
	{
		id: 0,
		title: 'Здоровье конечностей',
		key: EnumForHealthTabs.RoofHealth,
	},
	{
		id: 1,
		title: 'Упитанность',
		key: EnumForHealthTabs.Fatness,
	},
]
