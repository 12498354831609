import {
	Box,
	Checkbox,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material'
import { ReactComponent as HoofIcon } from 'assets/hoof-icon.svg'
import { ReactComponent as CheckBox } from 'assets/icons/checkBox.svg'
import CheckBoxActive from 'assets/icons/checkBoxActive'
import { ReactComponent as TagNumberIcon } from 'assets/icons/tag-icon.svg'
import { ReactComponent as EditLogo } from 'assets/ration_edit_logo.svg'
import { morphForWords } from 'components/shared/processes'
import { ICowsList } from 'models/ICattlesData'
import moment from 'moment'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { COLORS } from 'styles/colors'
import TableSkeleton from 'ui/skeleton/TableSkeleton'
import TooltipHover from 'ui/TooltipHover'
import { TypographyPrimary } from 'ui/typography/TypographyPrimary'
import { TypographySecondary } from 'ui/typography/TypographySecondary'
import { TypographySecondary14 } from 'ui/typography/TypographySecondary14'

type TrimTableProps = {
	cattlesInfo: ICowsList[]
	fetchingCowsList: boolean
	toggleCheckbox: (id) => void
	toggleAllCheckboxes: () => void
	selectedCheckboxes: number[]
}

const TrimTable = (props: TrimTableProps) => {
	const {
		cattlesInfo,
		fetchingCowsList,
		toggleCheckbox,
		toggleAllCheckboxes,
		selectedCheckboxes,
	} = props
	const navigate = useNavigate()

	const handleEditTrim = (el: ICowsList) => {
		el.tag_id && navigate('/cattles/trim/edit', { state: [el] })
	}

	const handleCheckupTrim = (el: ICowsList) => {
		el.tag_id && navigate('/cattles/trim/check-up', { state: [el] })
	}

	const isActiveAllCheckboxes = useMemo(
		() =>
			cattlesInfo?.length === selectedCheckboxes.length &&
			selectedCheckboxes.length !== 0,
		[selectedCheckboxes]
	)

	return (
		<TableContainer
			sx={{
				marginTop: '4px',
				overflow: 'auto',
				height: 'calc(100vh - 350px)',
				'&::-webkit-scrollbar': {
					width: 4,
					height: 4,
				},
				'&::-webkit-scrollbar-track': {
					backgroundColor: '#DBDBDB',
					borderRadius: 12,
				},
				'&::-webkit-scrollbar-thumb': {
					backgroundColor: '#5222D0',
					borderRadius: 12,
				},
			}}
		>
			<Table>
				<TableHead
					sx={{
						[`& .${tableCellClasses.root}`]: {
							backgroundColor: '#fff',
						},
					}}
				>
					<TableRow sx={{ width: '100%', height: '50px' }}>
						<TableCell sx={{ width: '1%' }} padding={'none'}>
							<Checkbox
								onClick={() => toggleAllCheckboxes()}
								sx={{
									color: COLORS.inputBorder,
									'&.MuiCheckbox-root': {
										margin: '0px',
										padding: '0px',
										width: '20px',
										height: '20px',
										'&.Mui-checked': {
											color: COLORS.main,
										},
									},
								}}
								disableRipple
								icon={<CheckBox />}
								checkedIcon={<CheckBoxActive />}
								checked={isActiveAllCheckboxes}
							/>
						</TableCell>
						<TableCell
							align={'left'}
							padding={'none'}
							sx={{
								minWidth: '85px',
								left: 0,
								background: 'white',
							}}
						>
							<Box
								display={'flex'}
								flexDirection={'row'}
								alignItems={'center'}
								gap={'4px'}
							>
								<TagNumberIcon />
								<TypographySecondary>
									Номер
									<br />
									бирки
								</TypographySecondary>
							</Box>
						</TableCell>
						<TableCell
							align={'left'}
							padding={'none'}
							sx={{ minWidth: '180px' }}
						>
							<TypographySecondary>Группа</TypographySecondary>
						</TableCell>
						<TableCell
							align={'left'}
							padding={'none'}
							sx={{
								minWidth: '130px',
								background: 'white',
							}}
						>
							<TypographySecondary>
								{' '}
								Хромота
								<br />
								Продолжительность
							</TypographySecondary>
						</TableCell>
						<TableCell
							align={'left'}
							padding={'none'}
							sx={{
								minWidth: '130px',
								background: 'white',
							}}
						>
							<TypographySecondary>
								{' '}
								Дата последней
								<br />
								обрезки
							</TypographySecondary>
						</TableCell>
						<TableCell
							align={'left'}
							padding={'none'}
							sx={{
								minWidth: '130px',
								background: 'white',
							}}
						>
							<TypographySecondary>
								{' '}
								Дата следующей
								<br />
								обрезки
							</TypographySecondary>
						</TableCell>
						<TableCell
							align={'left'}
							padding={'none'}
							sx={{ minWidth: '160px' }}
						>
							<TypographySecondary>Исполнитель</TypographySecondary>
						</TableCell>
						<TableCell
							align={'right'}
							padding={'none'}
							sx={{
								minWidth: '100px',
								paddingRight: '5px',
							}}
						>
							<TypographySecondary>Действия</TypographySecondary>
						</TableCell>
					</TableRow>
				</TableHead>
				{fetchingCowsList ? (
					<TableBody>
						<TableRow>
							<TableCell colSpan={12} align={'center'} padding={'none'}>
								<TableSkeleton
									widthForBody={'100%'}
									heightForBody={'34px'}
									rowCount={36}
									totalHeight={'calc(100vh - 350px)'}
								/>
							</TableCell>
						</TableRow>
					</TableBody>
				) : cattlesInfo.length === 0 ? (
					<TableBody>
						<TableRow
							sx={{
								width: '100%',
								[`& .${tableCellClasses.root}`]: {
									borderBottom: 'none',
									height: '300px',
								},
							}}
						>
							<TableCell colSpan={8} align={'center'} padding={'none'}>
								<TypographySecondary14>
									Запланированных обрезок не обнаружено
								</TypographySecondary14>
							</TableCell>
						</TableRow>
					</TableBody>
				) : (
					<TableBody>
						{cattlesInfo?.map((el, index) => {
							const diffLastTrimMs = moment().diff(el.trim_last_dt)
							const diffLastTrim = moment(diffLastTrimMs).format('D')
							return (
								<TableRow
									key={index}
									sx={{
										height: '46px',
										'&.hover:': {
											backgroundColor: COLORS.mainOpacity,
										},
										[`& .${tableCellClasses.root}`]: {
											borderBottom: 'none',
											'&.hover:': {
												backgroundColor: COLORS.mainOpacity,
											},
										},
									}}
								>
									<TableCell
										sx={{ minWidth: '40px', verticalAlign: 'top' }}
										align={'left'}
										padding={'none'}
									>
										<Checkbox
											onClick={() => toggleCheckbox(el.tag_id)}
											sx={{
												color: COLORS.inputBorder,
												'&.MuiCheckbox-root': {
													margin: '6px 0px',
													padding: '0px',
													width: '20px',
													height: '20px',
													'&.Mui-checked': {
														color: COLORS.main,
													},
												},
											}}
											disableRipple
											icon={<CheckBox />}
											checkedIcon={<CheckBoxActive />}
											checked={selectedCheckboxes.includes(el.tag_id)}
										/>
									</TableCell>
									<TableCell
										align={'left'}
										padding={'none'}
										sx={{
											verticalAlign: 'top',
											padding: '8px 0px',
											minWidth: '85px',
											background: 'white',
										}}
									>
										<TypographyPrimary>{el.tag_id}</TypographyPrimary>
									</TableCell>
									<TableCell
										align={'left'}
										padding={'none'}
										sx={{
											verticalAlign: 'top',
											padding: '8px 0px',
											minWidth: '180px',
											background: 'white',
										}}
									>
										<TypographyPrimary>{el.group.title}</TypographyPrimary>
									</TableCell>
									<TableCell
										align={'left'}
										padding={'none'}
										sx={{
											verticalAlign: 'top',
											padding: '8px 0px',
											minWidth: '180px',
											background: 'white',
										}}
									>
										{el.limpness && (
											<TypographyPrimary>
												{el.limpness === 1 ? 'Здоровая' : 'Хромая'}{' '}
												<span
													style={{ fontSize: '12px', color: COLORS.secondary }}
												>
													{/* {morphForWords(el.limpness, [
														'балл',
														'балла',
														'баллов',
													])}{' '} */}
													<br />{' '}
													{el.limpness_length
														? `${el.limpness_length} ${morphForWords(
																el.limpness_length,
																['день', 'дня', 'дней']
														  )}`
														: '-'}
												</span>
											</TypographyPrimary>
										)}
									</TableCell>
									<TableCell
										align={'left'}
										padding={'none'}
										sx={{ verticalAlign: 'top', padding: '8px 0px' }}
									>
										{el.trim_last_dt ? (
											<>
												<TypographySecondary14>
													{moment(el.trim_last_dt).format('D.MM.YY')}
												</TypographySecondary14>
												<TypographySecondary14>
													{`${diffLastTrim} ${morphForWords(diffLastTrim, [
														'день',
														'дня',
														'дней',
													])}`}
												</TypographySecondary14>
											</>
										) : (
											<TypographySecondary14>
												Не назначено
											</TypographySecondary14>
										)}
									</TableCell>
									<TableCell
										align={'left'}
										padding={'none'}
										sx={{ verticalAlign: 'top', padding: '8px 0px' }}
									>
										<TypographyPrimary
											sx={{
												color: moment().isAfter(el.trim_next_dt)
													? COLORS.error
													: COLORS.black,
											}}
										>
											{el.trim_next_dt
												? moment(el.trim_next_dt).format('D.MM.YY')
												: 'Не назначено'}
										</TypographyPrimary>
									</TableCell>
									<TableCell
										align={'left'}
										padding={'none'}
										sx={{ verticalAlign: 'top', padding: '8px 0px' }}
									>
										{el.trim_responsible_last_name &&
										el.trim_responsible_first_name ? (
											<>
												<TypographyPrimary>
													{`${el.trim_responsible_last_name}. ${el.trim_responsible_first_name[0]}`}
												</TypographyPrimary>
												{el.trim_responsible_position && (
													<TypographySecondary>
														{el.trim_responsible_position}
													</TypographySecondary>
												)}
											</>
										) : (
											<TypographySecondary14>
												{'Не назначен'}
											</TypographySecondary14>
										)}
									</TableCell>
									<TableCell
										align={'right'}
										padding={'none'}
										sx={{
											verticalAlign: 'top',
											padding: '8px 0px',
											position: 'sticky',
											right: 0,
											background: 'white',
										}}
									>
										<Box
											display={'flex'}
											flexDirection={'row'}
											alignItems={'center'}
											gap={'12px'}
											justifyContent={'end'}
											mr={'5px'}
										>
											<TooltipHover
												title={'Редактировать обрезку'}
												placement='bottom-end'
											>
												<EditLogo
													onClick={() => handleEditTrim(el)}
													style={{ cursor: 'pointer' }}
													fill={COLORS.icon}
												/>
											</TooltipHover>
											<TooltipHover
												title={'Провести обрезку'}
												placement='bottom-end'
											>
												<HoofIcon
													onClick={() => handleCheckupTrim(el)}
													style={{
														cursor: 'pointer',
													}}
													fill={'transparent'}
													stroke={COLORS.secondary}
												/>
											</TooltipHover>
										</Box>
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				)}
			</Table>
		</TableContainer>
	)
}

export default TrimTable
