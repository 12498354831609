import { IDescription } from './models'

export const generateColorsAndValuesArray = (array: IDescription[]) => {
	const categories = []
	const colors = []
	if (array) {
		for (let i = 0; i < array?.length; i++) {
			categories.push(array[i].value)
			colors.push(array[i].color === '#5222D0' ? array[i].color : '#EB4D3D')
		}
	}
	return { categories, colors }
}
