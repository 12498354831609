import { Box } from '@mui/material'
import { TypographySecondary } from 'ui/typography/TypographySecondary'

const NotAvailableModule = () => {
	return (
		<Box display={'flex'} flexDirection={'column'}>
			<TypographySecondary>
				Для доступа к информации по упитанности
				<a
					href='https://smartfarm.vision/fatness'
					target='_blank'
					style={{
						color: '#7f7f84',
						fontSize: '12px',
						fontWeight: 400,
						textDecoration: 'underline',
						textUnderlineOffset: '3px',
					}}
				>
					приобретите
				</a>{' '}
				модуль «Упитанность».
			</TypographySecondary>
		</Box>
	)
}

export default NotAvailableModule
