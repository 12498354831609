import { Box } from '@mui/material'
import { ICowLightInfo } from 'models/ICattlesData'
import InfoWidjet from '../shared/InfoWidjet'
import PhotoWidjet from '../shared/PhotoWidjet'

type MainInfoPropsType = {
	cattleInfo: ICowLightInfo
}

const CowMainInfo = (props: MainInfoPropsType) => {
	const { cattleInfo } = props
	return (
		<Box
			sx={{
				width: '100%',
				height: '245px',
				display: 'flex',
				flexDirection: 'row',
				gap: '24px',
				'@media(max-width: 1200px)': {
					flexDirection: 'column',
				},
			}}
		>
			<PhotoWidjet images={cattleInfo?.images} />
			<InfoWidjet cattleInfo={cattleInfo} />
			{/* <FoodInfoWidjet cattleInfo={cattleInfo} /> */}
		</Box>
	)
}

export default CowMainInfo
