import { ThemeProvider } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { Box } from '@mui/system'
import { ReactComponent as MenuIconCattles } from 'assets/icons/menu_cattles.svg'
import { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { TypographyPrimary16Bold } from 'ui/typography/TypographyPrimary16Bold'
import { drawerWidth } from 'variables'
import { selectPermissions } from '../store/authSlice'
import { useAppSelector } from '../store/hooks'
import { fontTheme } from '../styles/font'

const theme = fontTheme
function findMatchingKey(currentPath, menuSections) {
	let bestMatch = ''
	let maxMatchLength = 0

	menuSections.forEach(e => {
		const sectionPath = e.key
		const commonPart = findCommonPart(currentPath, sectionPath)

		if (commonPart.length > maxMatchLength) {
			maxMatchLength = commonPart.length
			bestMatch = sectionPath
		}
	})
	return bestMatch
}

function findCommonPart(str1, str2) {
	let commonPart = ''
	const minLength = Math.min(str1.length, str2.length)

	for (let i = 0; i < minLength; i++) {
		if (str1[i] === str2[i]) {
			commonPart += str1[i]
		} else {
			break
		}
	}
	return commonPart
}

const SidebarMainMenu = () => {
	const permissions = useAppSelector(selectPermissions)
	const location = useLocation()

	const sidebarMenuSections = [
		{
			key: '/cattles',
			value: 'Поголовье',
			icon: <MenuIconCattles fill={'white'} />,
		},
		// permissions?.can_view_foodtable_main && {
		// 	key: '/feed',
		// 	value: 'Кормовой стол',
		// 	icon: <FeedTableIcon stroke={'white'} />,
		// },
		// permissions?.can_view_cattle_groups && {
		// 	key: '/feed/groups',
		// 	value: 'Группы',
		// 	icon: <GroupsIcon stroke={drawerWidth === 230  ? 'transparent' : 'white'} />,
		// },
		// permissions?.can_view_rations && {
		// 	key: '/feed/ration',
		// 	value: 'Рационы',
		// 	icon: <RationsIcon stroke={drawerWidth === 230  ? 'transparent' : 'white'}  />,
		// },
		// permissions?.can_view_feedplans && {
		// 	key: '/feed/plan',
		// 	value: 'План кормления',
		// 	icon: <EatingPlanIcon stroke={drawerWidth === 230  ? 'transparent' : 'white'}  />,
		// },
		// permissions?.can_view_analytics && {
		// 	key: '/feed/analytics',
		// 	value: 'Аналитика',
		// 	icon: <AnalyticsIcon fill={drawerWidth === 230  ? 'transparent' : 'white'}  />,
		// },
	].filter(Boolean)

	const [activeSection, setActiveSection] = useState(null)

	useEffect(() => {
		const currentPath = location.pathname
		const matchingKey = findMatchingKey(currentPath, sidebarMenuSections)
		setActiveSection(matchingKey)
	}, [location.pathname, sidebarMenuSections])

	return (
		<ThemeProvider theme={theme}>
			<Box
				sx={{
					marginTop: '10%',
					fontFamily: theme.typography.fontFamily,
				}}
			>
				<List sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
					{sidebarMenuSections?.map(e => {
						return (
							<ListItem
								key={e.key}
								sx={{
									padding: '0px',
								}}
							>
								<NavLink
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										width: '100%',
										justifyContent: 'flex-start',
										height: '40px',
										backgroundColor:
											activeSection === e.key
												? 'rgba(255, 255, 255, 0.15)'
												: 'transparent',
										borderRadius: '12px',
										gap: '4px',
									}}
									to={e.key}
								>
									<Box sx={{ pt: '4px' }}>{e.icon}</Box>
									{drawerWidth === 230 && (
										<TypographyPrimary16Bold sx={{ color: 'white' }}>
											{e.value}
										</TypographyPrimary16Bold>
									)}
								</NavLink>
							</ListItem>
						)
					})}
				</List>
			</Box>
		</ThemeProvider>
	)
}

export default SidebarMainMenu
